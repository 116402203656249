import { createReducer, on } from '@ngrx/store';

import {
  resetRouteRandomization,
  updateRouteRandomization,
} from './route-randomize.actions';

export const initialState = false;

export const routeRandomizationReducer = createReducer(
  initialState,
  on(
    updateRouteRandomization,
    (state, { toggleRouteRandomization }) => toggleRouteRandomization
  ),
  on(resetRouteRandomization, () => initialState)
);
