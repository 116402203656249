import { createAction, props } from '@ngrx/store';

const UPDATE_ACTION = 'UPDATE ROUTE RANDOMIZATION';
const RESET_ACTION = 'RESET ROUTE RANDOMIZATION';

export const updateRouteRandomization = createAction(
  UPDATE_ACTION,
  props<{ toggleRouteRandomization: boolean }>()
);

export const resetRouteRandomization = createAction(RESET_ACTION);
