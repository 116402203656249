import { ViewportScroller } from '@angular/common';
import { HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, Scroll } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { filter } from 'rxjs/operators';

import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppComponent } from 'app/app.component';
import { environment } from 'environments/environment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { NgxPaginationModule } from 'ngx-pagination';

import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@core/layout/layout.module';

import { defaultConfig, testConfig } from '@configs/fuse-config';
import { ADConfig } from '@configs/fuse-config/ad-config.model';

import { mobileFilterToggleReducer } from '@state/mobile-search-filter/mobile-search-filter.reducer';
import { routeRandomizationReducer } from '@state/route-randomize/route-randomize.reducer';
import { searchFilterReducer } from '@state/search-filter/search-filter.reducer';
import { searchKeywordReducer } from '@state/search-keyword/search-keyword.reducer';
import { searchLocationReducer } from '@state/search-location/search-location.reducer';

import { FuseProgressBarModule } from '@shared/@fuse/components';
import { FuseModule } from '@shared/@fuse/fuse.module';
import { SharedModule } from '@shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    AppModule,
    BrowserAnimationsModule,

    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),

    // Fuse modules
    FuseModule.forRoot(setWhiteLabel()),
    FuseProgressBarModule,

    NgxGoogleAnalyticsModule.forRoot(
      environment.GOOGLE_ANALYTICS_TRACKING_CODE
    ),
    NgxGoogleAnalyticsRouterModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GOOGLE_TAG_MANAGER_ID,
    }),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // App modules
    AppRoutingModule,

    CoreModule,
    LayoutModule,
    SharedModule,
    NgxDaterangepickerMd.forRoot(),
    NgxPaginationModule,

    StoreModule.forRoot({
      searchFilter: searchFilterReducer,
      searchKeyword: searchKeywordReducer,
      toggleFromMapFilter: mobileFilterToggleReducer,
      searchLocation: searchLocationReducer,
      toggleRouteRandomization: routeRandomizationReducer,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [Title],
})
export class AppBrowserModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 50]);
    router.events
      .pipe(filter(e => e instanceof Scroll))
      .subscribe((e: Scroll) => {
        if (e.anchor) {
          // anchor navigation
          /* setTimeout is the core line to solve the solution */
          setTimeout(() => {
            viewportScroller.scrollToAnchor(e.anchor);
          });
        } else if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}

export function setWhiteLabel(): ADConfig {
  if (environment.whiteLabelConfig.id == 'test') {
    return testConfig;
  }

  return defaultConfig;
}
