export interface AnyObject {
  [key: string]: any;
}

export function filterEmptyKeys(obj: AnyObject) {
  const newObj: AnyObject = {};
  for (const [key, value] of Object.entries(obj).filter(value => value)) {
    if (value) {
      newObj[key] = value;
    }
  }

  return newObj;
}

export function convertObjectToString(objItem: AnyObject) {
  return Object.entries(objItem)
    .map(res => {
      if (res[1]) {
        return res[0];
      }
      return '';
    })
    .filter(res => res)
    .join();
}
